<template>
	<div>
		<base-header class="pb-6" type="">
			<div class="row align-items-center py-4">
				<div class="col-lg-6 col-7">
					<h6 class="h2 d-inline-block mb-0">Dashboard</h6>
				</div>
			</div>
		</base-header>

		<!--Charts-->
		<div class="container-fluid mt--6"  v-loading="loading">
			<div class="card-deck flex-column flex-xl-row">
				<card header-classes="bg-transparent">
					<div slot="header" class="row align-items-center">
						<div class="col">
							<h6 class="text-uppercase text-muted ls-1 mb-1">Overview</h6>
							<h5 class="h3 mb-0">Lead Value (AU)</h5>
						</div>
					</div>
					<line-chart :height="175" ref="bigChart" :chart-data="leadValueAU" :extra-options="totalBudgetOptions">
					</line-chart>
				</card>
				<card header-classes="bg-transparent">
					<div slot="header" class="row align-items-center">
						<div class="col">
							<h6 class="text-uppercase text-muted ls-1 mb-1">Overview</h6>
							<h5 class="h3 mb-0">Lead Value (UK)</h5>
						</div>
					</div>
					<line-chart :height="175" ref="bigChart" :chart-data="leadValueUK" :extra-options="totalBudgetOptions">
					</line-chart>
				</card>
			</div>
			<div class="card-deck flex-column flex-xl-row">
				<card header-classes="bg-transparent">
					<div slot="header" class="row align-items-center">
						<div class="col">
							<h6 class="text-uppercase text-muted ls-1 mb-1">Performance</h6>
							<h5 class="h3 mb-0">Total Leads (AU)</h5>
						</div>
					</div>
					<bar-chart
						:height="175"
						ref="barChart"
						:chart-data="totalLeadCountAU"
						:extra-options="totalLeadCountOptions"
					>
					</bar-chart>
				</card>
				<card header-classes="bg-transparent">
					<div slot="header" class="row align-items-center">
						<div class="col">
							<h6 class="text-uppercase text-muted ls-1 mb-1">Performance</h6>
							<h5 class="h3 mb-0">Total Leads (UK)</h5>
						</div>
					</div>
					<bar-chart
						:height="175"
						ref="barChart"
						:chart-data="totalLeadCountUK"
						:extra-options="totalLeadCountOptions"
					>
					</bar-chart>
				</card>
			</div>
			<div class="card-deck flex-column flex-xl-row">
				<card header-classes="bg-transparent">
					<div slot="header" class="row align-items-center">
						<div class="col-8">
							<h5 class="h3 mb-0">Leads by Venue - current month (AU)</h5>
						</div>
						<div class="col-4 text-right">
							<router-link
								:to="{ name: 'AllLeads' }"
								class="btn btn-sm btn-primary"
							>
								See all
							</router-link>
							<!-- <base-button size="sm" type="primary">See all</base-button> -->
						</div>
					</div>
					<leads-growth-table label-head="Venue" type="venue" :growth-data="venueByMonthAU"/>
				</card>
				<card header-classes="bg-transparent">
					<div slot="header" class="row align-items-center">
						<div class="col-8">
							<h5 class="h3 mb-0">Leads by Venue - current month (UK)</h5>
						</div>
						<div class="col-4 text-right">
							<router-link
								:to="{ name: 'AllLeads' }"
								class="btn btn-sm btn-primary"
							>
								See all
							</router-link>
							<!-- <base-button size="sm" type="primary">See all</base-button> -->
						</div>
					</div>
					<leads-growth-table label-head="Venue" type="venue" :growth-data="venueByMonthUK"/>
				</card>
			</div>
			<div class="card-deck flex-column flex-xl-row">
				<card header-classes="bg-transparent">
					<div slot="header" class="row align-items-center">
						<div class="col">
							<h5 class="h3 mb-0">Leads by Venue Specialist - current month (AU)</h5>
						</div>
					</div>
					<leads-growth-table label-head="Venue Specialist" type="venue-specialist" :growth-data="venueSpecialistByMonthAU"/>
				</card>
				<card header-classes="bg-transparent">
					<div slot="header" class="row align-items-center">
						<div class="col">
							<h5 class="h3 mb-0">Leads by Venue Specialist - current month (UK)</h5>
						</div>
					</div>
					<leads-growth-table label-head="Venue Specialist" type="venue-specialist" :growth-data="venueSpecialistByMonthUK"/>
				</card>
			</div>
			<!-- End charts-->
		</div>
	</div>
</template>
<script>
// Charts
import * as chartConfigs from "@/components/Charts/config";
import LineChart from "@/components/Charts/LineChart";
import BarChart from "@/components/Charts/BarChart";
import LeadsGrowthTable from '@/views/Widgets/Dashboard/LeadsGrowthTable';

// Components

import DashboardService from "@/services/dashboard.service";
import { mapState } from "vuex";

export default {
	data() {
		return {
			loading: true,
			totalBudgetOptions: {
				tooltips: {
					enabled: true,
					callbacks: {
						title: function(data, test) {
							let label = data[0].xLabel;
							return label.substr(0, 3);
						},
						label: function(tooltipItems, data) {
							let excluded = parseInt(tooltipItems.label.replace(/.*:EV=/, ''));
							let country = tooltipItems.label.replace(/.*:CS=/, '').substr(0, 2);
							let locale = country === 'au' ? 'en-AU' : (country === 'uk' ? 'en-GB' : '');
							let currency = country === 'au' ? 'AUD' : (country === 'uk' ? 'GBP' : '');
							let label = new Intl.NumberFormat(locale, { maximumSignificantDigits: 10, style: 'currency', currency: currency }).format(tooltipItems.yLabel);
							return label + ((excluded) ? ` ( ${excluded} leads not counted )` : '');
						}
					}
				},
				legend: {
					display: false
				},
				scales: {
					xAxes: [{
						stacked: true,
						categoryPercentage: 0.5,
						barPercentage: 1,
						ticks: {
							callback: function(label, index, labels) {
								return label.substr(0, 3);
							}
						}
					}],
					yAxes: [{
						stacked: true,
						ticks: {
							callback: function(label) {
								return new Intl.NumberFormat( 'en-US', { maximumFractionDigits: 1,notation: "compact" , compactDisplay: "short" }).format(label);
							}
						}
					}]
				}
			},
			totalLeadCountOptions: {
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					xAxes: [{
						stacked: true,
						categoryPercentage: 0.5,
						barPercentage: 1
					}],
					yAxes: [{
						stacked: true
					}]
				},
				tooltips: {
					enabled: true,
					callbacks: {
						label: ((tooltipItems, data) => {
							if (tooltipItems.datasetIndex === 1 && tooltipItems.yLabel === 0) {
								return '';
							}
							let label = tooltipItems.datasetIndex ===  0 ? 'Current' : 'Projected';
							return label + ': ' + tooltipItems.yLabel;
						})
					}
				}
			}
		};
	},
	components: {
		LineChart,
		BarChart,
		LeadsGrowthTable
	},
	async created() {
		await this.$store.dispatch("dashboard/getAll");
		await this.$store.dispatch("dashboard/getAllMonth");
		this.loading = false;
	},
	computed: mapState({
		leadValueAU(state) {
			let data = state.dashboard.data.au || {};
			return DashboardService.formatLeadValueData('au',data);
		},
		leadValueUK(state) {
			let data = state.dashboard.data.uk || {};
			return DashboardService.formatLeadValueData('uk', data);
		},
		totalLeadCountAU(state) {
			let data = state.dashboard.data.au || {};
			return DashboardService.formatLeadTotalData(data);
		},
		totalLeadCountUK(state) {
			let data = state.dashboard.data.uk || {};
			return DashboardService.formatLeadTotalData(data);
		},
		venueByMonthAU(state) {
			let data = state.dashboard.dataByMonth.au ?  state.dashboard.dataByMonth.au.venues : [];
			return DashboardService.formatByMonth(data);
		},
		venueByMonthUK(state) {
			let data = state.dashboard.dataByMonth.uk ?  state.dashboard.dataByMonth.uk.venues : [];
			return DashboardService.formatByMonth(data);
		},
		venueSpecialistByMonthAU(state) {
			let data = state.dashboard.dataByMonth.au ?  state.dashboard.dataByMonth.au.venue_specialists : [];
			return DashboardService.formatByMonth(data);
		},
		venueSpecialistByMonthUK(state) {
			let data = state.dashboard.dataByMonth.uk ?  state.dashboard.dataByMonth.uk.venue_specialists : [];
			return DashboardService.formatByMonth(data);
		}
	})
};
</script>
<style></style>

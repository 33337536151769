<template>
  <div class="table-responsive">
    <el-table
      class="table-responsive table"
      :data="growthData"
      header-row-class-name="thead-light"
    >
      <el-table-column :label="labelHead" min-width="255px" prop="name">
        <template v-slot="{ row }">
          <router-link
            :to="{ name: 'VenueDetails', params: { id: row.id }}"
            v-if="type === 'venue'"
          >
            {{ row.name }}
          </router-link>
          <div class="font-weight-600" v-else>{{ row.name }}</div>
        </template>
      </el-table-column>

      <el-table-column label="Leads" min-width="110px" prop="leads">
      </el-table-column>

      <el-table-column min-width="220px" prop="progress">
        <template v-slot="{ row }">
          <div class="d-flex align-items-center">
            <span class="mr-2">{{ row.progress }}%</span>
            <base-progress type="gradient-success" :value="convertProgress(row.progress)" />
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {  Table, TableColumn } from "element-ui";
export default {
  name: "leads-growth-table",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    growthData: {
      required: true,
    },
    labelHead: {
      required: true,
    },
    type: {
      required: true,
    },
  },
  methods: {
    convertProgress(progress) {
      return parseFloat(progress);
    },
  },
};
</script>
<style></style>
